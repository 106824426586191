var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("flow-viewer", {
    attrs: {
      operation: _vm.operation,
      taskDataId: _vm._taskDataId,
      readonly: false,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }